import { Link } from "react-router-dom";

export default function PrivacyPolicy() {
  return (
    <>
      <div className="container mt-3">
        <div className="row">
          <div className="col-12 d-flex justify-content-center justify-content-md-start">
            <Link
              to="/"
              className="d-flex align-items-center text-decoration-none"
            >
              <img
                src="/images/logo.png"
                alt="Logo"
                style={{ height: "50px" }}
              />
            </Link>
          </div>
        </div>
      </div>

      <hr className="w-100" />

      <div className="content-section container mt-4">
        <h1>POLÍTICA DE PRIVACIDADE DA PLATAFORMA CHANGEBACK</h1>

        <p>A presente Política de Privacidade tem por objetivo esclarecer as práticas de tratamento de dados realizadas pelo aplicativo Change Back, conforme as disposições da Lei Geral de Proteção de Dados (LGPD) - Lei nº 13.709/2018, assegurando a proteção e a privacidade dos dados pessoais de nossos usuários.</p>
        
        <ol>
          <li>DADOS COLETADOS</li>
          <p>
          O Change Back coleta os seguintes tipos de dados pessoais, fornecidos voluntariamente pelos usuários, com o propósito de prestar serviços de cashback:<br />
            <ul>
              <li>Dados de cadastro: Nome, CPF, e-mail, número de telefone e endereço.</li>
              <li>Dados bancários: Informações de conta bancária ou método de pagamento, utilizados exclusivamente para a transferência de valores de cashback.</li>
              <li>Dados de navegação: Cookies, IP, localização geográfica e comportamento dentro do aplicativo, para aprimoramento da experiência do usuário.</li>
              <li>Dados de compras: Histórico de compras realizadas por meio do aplicativo, a fim de processar a devolução de valores (cashback).</li>
            </ul>
          </p>
          <li>FINALIDADE DA COLETA DE DADOS</li>
          <p>Os dados coletados pelo Change Back são utilizados para:<br />
            <ul>
              <li>Fornecer os serviços de cashback e facilitar a realização de pagamentos;</li>
              <li>Personalizar a experiência dos usuários no aplicativo;</li>
              <li>Enviar comunicações sobre promoções, atualizações e suporte ao usuário;</li>
              <li>Cumprir obrigações legais e regulatórias;</li>
              <li>Melhorar a segurança dos usuários e prevenir fraudes.</li>
            </ul>
          </p>
          <li>COMPARTILHAMENTO DE DADOS</li>
          <p>O Change Back poderá compartilhar os dados pessoais coletados com:<br />
          <ul>
            <li>Parceiros Comerciais: Comércios afiliados para validação das transações elegíveis ao cashback.</li>
            <li>Instituições Financeiras: Para a efetivação de transferências relacionadas ao cashback.</li>
            <li>Autoridades Governamentais: Quando necessário para o cumprimento de obrigações legais ou por determinação judicial.</li>
          </ul>
          </p>
          <li>ARMAZENAMENTO E SEGURANÇA DOS DADOS</li>
          <p>Os dados pessoais são armazenados em servidores seguros, com uso de tecnologias de criptografia e práticas de segurança de dados que visam a proteger as informações contra acessos não autorizados, alterações, divulgações ou destruição.</p>
          <li>DIREITOS DOS TITULARES DOS DADOS</li>
          <p>Em conformidade com a LGPD, os usuários do Change Back possuem os seguintes direitos em relação aos seus dados pessoais:<br />
          <ul>
            <li>Confirmar a existência de tratamento de dados;</li>
            <li>Acessar os dados pessoais tratados;</li>
            <li>Corrigir dados incompletos, inexatos ou desatualizados;</li>
            <li>Solicitar a anonimização, bloqueio ou eliminação de dados desnecessários ou tratados em desconformidade com a lei;</li>
            <li>Revogar o consentimento para o tratamento de dados;</li>
            <li>Solicitar a portabilidade dos dados a outro fornecedor de serviço.</li>
          </ul>
          </p>
          <li>ALTERAÇÕES NA POLÍTICA DE PRIVACIDADE</li>
          <p>O Change Back se reserva o direito de modificar esta Política de Privacidade a qualquer momento, mediante aviso prévio aos usuários. Recomendamos a revisão periódica deste documento para estar ciente das eventuais mudanças.</p>
          <li>CONTATO</li>
          <p>Em caso de dúvidas, solicitações ou reclamações sobre a presente Política de Privacidade ou sobre o tratamento de dados pessoais, o usuário pode entrar em contato através do e-mail: sac@changeback.com.br.</p>
        </ol>
      </div>
    </>
  );
}
