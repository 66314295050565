import { useEffect, useState } from "react";
import { FaCreditCard } from "react-icons/fa";
import { SiPix } from "react-icons/si";
import QRCodeComponent from "./component/qrcode";
import { IoCopyOutline } from "react-icons/io5";
import SuccessModal from "./component/SuccessModal";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import api from "../../services/api-client";
import CashbackSaleModel from "../../Interfaces/models/cashback-sale.model";
import apiErrorHandler from "../../services/api-error-handler";
import { convertAmountToBRL } from "../../utils/convert-amout";
import ServiceResult from "../../Interfaces/service-result";
import { Input } from "../../components/form/inputs/input";
import SalesCheckoutPostArgs from "../../Interfaces/args/sales-checkout-post.args";
import { toast } from "react-toastify";
import moment from "moment";
import CashbackUseKind from "../../enums/cashback-use-kind";
import somenteNumeros from "../../utils/somente-numeros";
import SalesCheckoutModel from "../../Interfaces/models/sales-checkout.model";
import { PhoneNumberInput } from "../../components/form/inputs/phone-input";
import CashbackStatus from "../../enums/cashback-status";

enum PaymentType {
  Pix = "Pix",
  Cartao = "Cartao",
}

interface FormValues {
  fullName: string;
  cpfCnpj: string;
  phone: string;
  cashbackUseDate: string;
}

export default function Payment() {
  const { saleId } = useParams();
  const navigate = useNavigate();

  const [loadingSale, setLoadingSale] = useState<boolean>(true);
  const [sale, setSale] = useState<CashbackSaleModel | null>(null);
  const [loadingQrCode, setLoadingQrCode] = useState<boolean>(false);

  const [selectedPayment, setSelectedPayment] = useState<PaymentType>(
    PaymentType.Pix
  );

  const [qrCode, setQrCode] = useState<string | null>(null);
  const [transactionId, setTransactionId] = useState<string>("");

  const [isDateInputEnabled, setIsDateInputEnabled] = useState<boolean>(false);

  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
    clearErrors,
  } = useForm<FormValues>({
    values: {
      fullName: "",
      cpfCnpj: "",
      phone: "",
      cashbackUseDate: "",
    },
  });

  async function getSale(): Promise<void> {
    setLoadingSale(true);

    api
      .get<ServiceResult<CashbackSaleModel>>(`/sales/${saleId}`)
      .then(({ data }) => {
        setSale(data.result as CashbackSaleModel);
      })
      .catch((err) => {
        apiErrorHandler(err);
      })
      .finally(() => {
        setLoadingSale(false);
      });
  }

  const handlePaymentSelect = (payment: PaymentType) => {
    setSelectedPayment(payment);
  };

  async function handleGenerateQRCode(data: FormValues): Promise<void> {
    const selectedDate = moment(data.cashbackUseDate).format("YYYY-MM-DD");

    // limite 30 dias no futuro
    if (
      isDateInputEnabled &&
      moment(selectedDate).isAfter(moment().add(30, "days"))
    ) {
      toast.error(
        "Data de utilização do cashback não pode ser superior a 30 dias"
      );
      return;
    }

    // não pode ser data passada
    if (
      isDateInputEnabled &&
      moment(selectedDate).isBefore(moment().format("YYYY-MM-DD"))
    ) {
      toast.error(
        "Data de utilização do cashback não pode ser inferior a data atual"
      );
      return;
    }

    const formattedDocument = somenteNumeros(data.cpfCnpj);
    if (formattedDocument.length !== 11 && formattedDocument.length !== 14) {
      toast.error("CPF/CNPJ inválido");
      return;
    }

    setLoadingQrCode(true);

    const args: SalesCheckoutPostArgs = {
      fullName: data.fullName,
      mobilePhoneNumber: somenteNumeros(data.phone),
      nationalDocument: somenteNumeros(data.cpfCnpj),
      cashbackUseKind: isDateInputEnabled
        ? CashbackUseKind.Future
        : CashbackUseKind.Immediate,
      cashbackUseDate: isDateInputEnabled ? selectedDate : null,
    };

    api
      .post<ServiceResult<SalesCheckoutModel>>(
        `/sales/${saleId}/checkout`,
        args
      )
      .then(({ data }) => {
        if (!data.result?.pixCopiaCola) {
          toast.error("Ocorreu um erro ao gerar o QR Code");
          return;
        }

        setQrCode(data.result.pixCopiaCola);
        setTransactionId(data.result.cashbackTransactionId);

        toast.success("QR Code gerado com sucesso!");
      })
      .catch((err) => {
        apiErrorHandler(err);
      })
      .finally(() => {
        setLoadingQrCode(false);
      });
  }

  const handleCopyKey = () => {
    if (!qrCode) {
      toast.error("Nenhuma chave Pix encontrada");
      return;
    }

    navigator?.clipboard?.writeText(qrCode);
    toast.success("Chave Pix copiada para a área de transferência");
  };

  const handleChooseImmediate = () => {
    setIsDateInputEnabled(false);
    clearErrors("cashbackUseDate");
  };

  const handleChooseCustomDate = () => {
    setIsDateInputEnabled(!isDateInputEnabled);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!transactionId) return;

      api
        .get<ServiceResult<CashbackStatus>>(
          `/sales/checkout/${transactionId}/status`
        )
        .then(({ data }) => {
          if (data.result === CashbackStatus.Completed) {
            setShowSuccessModal(true);
            clearInterval(interval);
            toast.success("Pagamento efetuado com sucesso!");
          }
        })
        .catch((err) => {
          apiErrorHandler(err);
          clearInterval(interval);
        });
    }, 5e3);
  }, [transactionId]);

  useEffect(() => {
    getSale();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="min-vh-100">
      <SuccessModal show={showSuccessModal} onHide={() => navigate("/")} />

      <div className="container mt-3">
        <div className="row">
          <div className="col-12 d-flex justify-content-center justify-content-md-start">
            <Link
              to="/"
              className="d-flex align-items-center text-decoration-none"
            >
              <img
                src="/images/logo.png"
                alt="Logo"
                style={{ height: "50px" }}
              />
            </Link>
          </div>
        </div>
      </div>
      <hr className="w-100" />

      {loadingSale ? (
        <div className="mt-4 d-flex justify-content-center align-items-center">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : !sale ? (
        <div className="d-flex justify-content-center align-items-center">
          <p className="fs-3 text-danger mb-0">Venda não encontrada</p>
        </div>
      ) : (
        <div className="content-section container mt-4 ">
          <section className="row d-flex align-items-center justify-content-between">
            <div className="col-12 col-md-6 d-flex flex-column flex-md-row align-items-center">
              <img
                src={sale.companyLogoUrl || "/images/polishop.png"}
                alt="Logo"
                style={{ width: "100px" }}
                className="rounded"
              />
              <div className="ms-md-4 mt-3 mt-md-0 text-center text-md-start">
                <h2 className="fw-semibold fs-6 fw-bold m-0">
                  {convertAmountToBRL(sale.cashbackAmount)} de cashback (
                  {sale.companyDisplayName})
                </h2>
                <p className="fs-7 text-muted m-0">
                  Estabelecimento: {sale.companyDisplayName}
                </p>
                <p className="fs-5 fw-bold m-0" style={{ color: "#355CC0" }}>
                  Total a pagar: {convertAmountToBRL(sale.paymentAmount)}
                </p>
              </div>
            </div>
          </section>

          <img src="/images/Line.png" alt="Separador" className="w-100 mt-4" />

          <section className="mt-4">
            <h1 className="fs-5" style={{ color: "#74767D" }}>
              Quando deseja utilizar o cashback?
            </h1>
            <div className="mt-3">
              <div className="form-check">
                <input
                  id="immediate"
                  type="radio"
                  name="cashback-option"
                  className="form-check-input"
                  defaultChecked
                  onChange={handleChooseImmediate}
                />
                <label
                  className="form-check-label cursor-pointer"
                  htmlFor="immediate"
                >
                  Imediatamente
                </label>
              </div>

              <div className="form-check mt-2 d-flex align-items-center justify-content-between flex-column flex-md-row">
                <div className="d-flex align-items-center">
                  <input
                    id="custom-date"
                    type="radio"
                    name="cashback-option"
                    className="form-check-input"
                    onChange={handleChooseCustomDate}
                  />
                  <label
                    className="form-check-label ms-2 cursor-pointer"
                    htmlFor="custom-date"
                  >
                    Escolher data
                  </label>
                </div>

                <div className="align-items-center mt-md-0">
                  <Input
                    type="date"
                    control={control}
                    errors={errors}
                    name="cashbackUseDate"
                    validation={{
                      required: {
                        value: isDateInputEnabled,
                        message: "Data de utilização é obrigatória",
                      },
                    }}
                    disabled={!isDateInputEnabled}
                    label="Data de utilização"
                  />
                </div>
              </div>
            </div>
          </section>

          <hr className="w-100" />

          <div className="mt-4">
            <h1 className="fs-2 mb-4 mb-md-0">Pagamento</h1>
          </div>

          <form
            onSubmit={handleSubmit(handleGenerateQRCode)}
            className="p-2 p-md-5"
          >
            <h1 className="fs-4 mb-4" style={{ color: "#74767D" }}>
              Detalhes do Comprador
            </h1>

            <div className="row">
              <div className="col-md-12 form-group mt-3">
                <Input
                  control={control}
                  errors={errors}
                  name="fullName"
                  validation={{ required: "Nome completo é obrigatório" }}
                  label="Nome completo"
                />
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-md-6 form-group">
                <div className="d-flex flex-column">
                  <Input
                    control={control}
                    errors={errors}
                    name="cpfCnpj"
                    validation={{ required: "CPF / CNPJ é obrigatório" }}
                    label="CPF / CNPJ"
                  />
                  <small className="text-muted fs-6">
                    Informe o CPF ou CNPJ (Não use pontuação)
                  </small>
                </div>
              </div>

              <div className="col-md-6 form-group">
                <PhoneNumberInput
                  control={control}
                  errors={errors}
                  name="phone"
                  rules={{ required: "Telefone é obrigatório" }}
                />
              </div>
            </div>

            <img
              src="/images/Line02.png"
              alt="Separador"
              className="w-100 mt-4"
            />

            <div className="p-5">
              <h1 className="fs-4 mb-4" style={{ color: "#74767D" }}>
                Forma de pagamento
              </h1>

              <div className="d-flex flex-column flex-md-row justify-content-center align-items-center mb-4">
                <button
                  type="button"
                  className={`btn btn-outline me-5 border-0 border-bottom shadow-none d-flex align-items-center ${
                    selectedPayment === PaymentType.Pix
                      ? "text-black"
                      : "text-secondary"
                  }`}
                  onClick={() => handlePaymentSelect(PaymentType.Pix)}
                  style={{
                    width: "200px",
                    textAlign: "left",
                  }}
                >
                  <SiPix
                    style={{
                      height: "30px",
                      marginRight: "10px",
                      color: "#62B9AD",
                    }}
                  />
                  Pix
                </button>

                <button
                  type="button"
                  className={`btn btn-outline border-0 border-bottom shadow-none d-flex align-items-center ${
                    selectedPayment === PaymentType.Cartao
                      ? "text-black"
                      : "text-secondary"
                  }`}
                  onClick={() => handlePaymentSelect(PaymentType.Cartao)}
                  style={{
                    width: "200px",
                    textAlign: "left",
                  }}
                  disabled
                >
                  <FaCreditCard
                    style={{ height: "30px", marginRight: "10px" }}
                  />
                  Cartão de Crédito
                </button>
              </div>

              <div className="mt-5">
                {selectedPayment === PaymentType.Pix && qrCode && (
                  <>
                    <QRCodeComponent value={qrCode} />

                    <div
                      className="d-flex justify-content-center align-items-center mt-3"
                      style={{ position: "relative" }}
                    >
                      <input
                        type="text"
                        value={qrCode}
                        readOnly
                        className="form-control"
                        style={{
                          border: "none",
                          borderBottom: "1px solid #ccc",
                          borderRadius: 0,
                          boxShadow: "none",
                          width: "500px",
                        }}
                      />
                      <button
                        type="button"
                        className="btn border-0 p-0"
                        onClick={handleCopyKey}
                        aria-label="Copiar chave"
                        style={{ right: 0 }}
                      >
                        <IoCopyOutline
                          style={{
                            cursor: "pointer",
                            color: "#143166",
                            fontSize: "1.5rem",
                          }}
                        />
                      </button>
                    </div>
                  </>
                )}

                <div className="d-flex justify-content-center mt-5">
                  <button
                    type="submit"
                    className="btn border-0 border-primary rounded-pill px-4 fs-5"
                    style={{
                      background: "#143166",
                      width: "676px",
                      color: "#FFFFFF",
                    }}
                    disabled={loadingQrCode}
                  >
                    {loadingQrCode ? "Gerando..." : "Gerar Qr Code"}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}
