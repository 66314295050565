import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "../pages/main";
import Payment from "../pages/payment";
import Terms from "../pages/terms";
import PrivacyPolicy from "../pages/privacy-policy";

export default function AppRouter() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/checkout/:saleId" element={<Payment />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      </Routes>
    </Router>
  );
}
