import { Link } from "react-router-dom";

export default function Terms() {
  return (
    <>
      <div className="container mt-3">
        <div className="row">
          <div className="col-12 d-flex justify-content-center justify-content-md-start">
            <Link
              to="/"
              className="d-flex align-items-center text-decoration-none"
            >
              <img
                src="/images/logo.png"
                alt="Logo"
                style={{ height: "50px" }}
              />
            </Link>
          </div>
        </div>
      </div>

      <hr className="w-100" />

      <div className="content-section container mt-4 ">
        <h1>TERMOS DE USO DO USUÁRIO FINAL DA PLATAFORMA CHANGEBACK</h1>
        
        <p>O presente TERMOS DE USO DO USUÁRIO FINAL DA PLATAFORMA CHANGEBACK, citado como “TERMO DE USO” e estabelece os direitos e obrigações entre CHANGEBACK INTERMEDIACAO S.A., com sede na Avenida Cidade Jardim, 400, Jardim Paulistano, cidade de São Paulo/SP – CEP: 01454-000, inscrita no CNPJ/MF sob o nº 54.898.529/0001-01, neste ato representado na forma de seu contrato social e citada como “CHANGEBACK” e o(a) USUÁRIO(A) FINAL, citado(a) como “USUÁRIO(A)”, qualificado conforme dados cadastrais fornecidos pelo(a) próprio(a) no momento de criação de sua conta/acesso de uso individual. Desta forma, tanto CHANGEBACK quanto USUÁRIO(A) concordam em cumprir todas as cláusulas estabelecidas no presente TERMO DE USO, que são:</p>
        
        <ol>
          <li><strong>DEFINIÇÕES:</strong>
            <ol type="a">
              <li><strong>CASHBACK:</strong> É o montante em dinheiro devolvido em formato de desconto em compra futura pelo PARCEIRO sobre o valor total de uma COMPRA VÁLIDA feita pelo USUÁRIO(A).</li>
              <li><strong>TITULAR (de um CASHBACK):</strong> USUÁRIO(A) que detém a propriedade de um CASHBACK dentro da plataforma CHANGEBACK.</li>
              <li><strong>VENDEDOR(A):</strong> Todo usuário que recebe um CASHBACK e negocia a venda dentro da plataforma CHANGEBACK.</li>
              <li><strong>COMPRADOR(A):</strong> Todo USUÁRIO(A) que realiza uma compra de um CASHBACK dentro da plataforma CHANGEBACK.</li>
            </ol>
          </li>
          <br />
          <li><strong>DO CADASTRO E SEGURANÇA:</strong>
            <ol type="a">
              <li>O(A) USUÁRIO(A) deverá fornecer informações precisas e atualizadas durante seu cadastro na plataforma.</li>
              <li>É de responsabilidade do(a) USUÁRIO(A) manter a confidencialidade de suas credenciais de acesso.</li>
              <li>A CHANGEBACK não se responsabiliza por qualquer perda ou dano resultante do uso não autorizado da conta do(a) USUÁRIO(A).</li>
            </ol>
          </li>
          <br />
          <li><strong>DOS DIREITOS DO TITULAR DO CASHBACK:</strong>
            <ol type="a">
              <li>Uma vez recebido o CASHBACK, emitido por um ESTABELECIMENTO credenciado e ativo dentro da plataforma, o USUÁRIO(A) poderá realizar as seguintes operações:
                <ol type="i">
                  <li>Utilizar o CASHBACK no ESTABELECIMENTO emissor, ou;</li>
                  <li>Vender o CASHBACK para outro USUÁRIO(A) dentro da plataforma de forma direta, ou;</li>
                  <li>Permitir que a plataforma venda o CASHBACK automaticamente caso a respectiva opção esteja ativada.</li>
                </ol>
              </li>
            </ol>
          </li>
          <br />
          <li><strong>DO USO DO CASHBACK:</strong>
            <ol type="a">
              <li>Todo USUÁRIO(A), na condição de TITULAR de um CASHBACK poderá fazer uso dele no ESTABELECIMENTO emissor, de acordo com as regras do pré-estabelecidas no programa de fidelidade do próprio ESTABELECIMENTO.</li>
              <li>Havendo movimentação (crédito/débito) de CASHBACK na carteira do USUÁRIO(A), gerenciada pelo ESTABELECIMENTO, o mesmo será notificado da movimentação através da plataforma e o saldo da carteira poderá ser consultado dentro da plataforma.</li>
            </ol>
          </li>
          <br />
          <li><strong>DA VENDA DE CASHBACK:</strong>
            <ol type="a">
              <li>O CASHBACK será negociado para venda sempre por 50% (cinquenta por cento) do valor do desconto concedido pelo ESTABELECIMENTO quando da sua emissão.
                <ol type="i">
                  <li>Exemplo: CASHBACK no valor de R$ 500,00 será vendido por R$ 250,00.</li>
                </ol>
              </li>
              <li>Efetivada a venda do CASHBACK, do valor financeiro da operação, será descontada uma tarifa fixa de R$ 1,99 (um real e noventa e nove centavos) MAIS o valor referente a 10% (dez por cento), sobre o montante financeiro da transação, referente a comissão pelo processamento da transação pela plataforma CHANGEBACK.
                <ol type="i">
                  <li>Exemplo: Venda de R$ 500,00 em cashback onde o montante financeiro movimentado é de R$ 250,00, desconta-se R$ 1,99 + 10%, no caso R$ 26,99, e sobra líquido para o USUÁRIO(A) VENDEDOR(A) a quantia de R$ 223,01.</li>
                  <li>O valor líquido da transação estará disponível para o USUÁRIO(A) VENDEDOR(A) no oitavo dia após a confirmação do pagamento pelo USUÁRIO(A) COMPRADOR(A). Tal medida visa garantir ao USUÁRIO(A) COMPRADOR(A) o direito à desistência da compra, estabelecido no Código de Defesa do Consumidor vigente.</li>
                </ol>
              </li>
              <li>O valor referente a venda do CASHBACK será creditado na carteira digital do USUÁRIO(A) VENDEDOR(A) dentro da plataforma e poderá ser transferido para uma conta bancária de mesma titularidade do USUÁRIO(A) VENDEDOR(A) ou poderá ser utilizado para novas compras dentro da plataforma.</li>
              <li>Confirmado o pagamento pela compra do CASHBACK, será emitido um novo CASHBACK com as mesmas regras estabelecidas pelo ESTABELECIMENTO emissor quando da emissão do CASHBACK original, INCLUSIVE suas datas limite de utilização.</li>
              <li>O direito ao CASHBACK é transferido instantaneamente ao novo TITULAR, portanto poderá utilizá-lo a partir do momento que for confirmado o pagamento dele dentro da plataforma CHANGEBACK.
                <ol type="i">
                  <li>Optando o USUÁRIO(A) COMPRADOR(A) por um meio de pagamento cuja confirmação não seja instantânea (exemplo: Boleto bancário), se disponível dentro da plataforma, o uso do CASHBACK estará disponível somente após a confirmação do pagamento pela instituição financeira.</li>
                </ol>
              </li>
            </ol>
          </li>
          <br />
          <li><strong>DA POLÍTICA DE PRIVACIDADE:</strong>
            <ol type="a">
              <li>As informações pessoais do(a) USUÁRIO(A) serão tratadas de acordo com a Política de Privacidade da Empresa, disponível em [link da Política de Privacidade].</li>
            </ol>
          </li>
          <br />
          <li><strong>DAS ALTERAÇÕES NO PRESENTE TERMO:</strong>
            <ol type="a">
              <li>A CHANGEBACK se reserva o direito de modificar este TERMO a qualquer momento.</li>
              <li>As alterações serão comunicadas ao USUÁRIO(A) através de e-mail cadastrado ou mediante aviso dentro da plataforma.</li>
              <li>O uso contínuo da plataforma após a publicação das alterações constitui a aceitação delas.</li>
            </ol>
          </li>
          <br />
          <li><strong>DO CANCELAMENTO E ENCERRAMENTO DE CONTA:</strong>
            <ol type="a">
              <li>O(A) USUÁRIO(A) poderá cancelar sua conta a qualquer momento mediante solicitação à CHANGEBACK.</li>
              <li>A CHANGEBACK poderá encerrar a conta do(a) USUÁRIO(A) em caso de violação deste Termo ou uso indevido da plataforma.</li>
            </ol>
          </li>
          <br />
          <li><strong>DA LIMITAÇÃO DE RESPONSABILIDADE:</strong>
            <ol type="a">
              <li>A CHANGEBACK não se responsabiliza por qualquer falha técnica, interrupção ou indisponibilidade.</li>
              <li>Em nenhum caso, a responsabilidade da CHANGEBACK excederá o valor total financeiro do CASHBACK processado pelo(a) USUÁRIO(A).</li>
            </ol>
          </li>
          <br />
          <li><strong>DA LEGISLAÇÃO APLICÁVEL E FORO:</strong>
            <ol type="a">
              <li>Este TERMO será regido e interpretado de acordo com as leis do Brasil.</li>
              <li>Fica eleito o foro do consumidor, com exclusão de qualquer outro, por mais privilegiado que seja, para dirimir quaisquer questões oriundas deste Termo.</li>
            </ol>
          </li>
        </ol>
        <p>Ao utilizar a plataforma CHANGEBACK, o(a) USUÁRIO(A) declara ter lido, compreendido e aceitado todas as condições aqui estabelecidas.</p>
      </div>
    </>
  );
}
