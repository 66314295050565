import React from "react";
import QRCode from "react-qr-code";

interface QRCodeComponentProps {
  value: string;
}

const QRCodeComponent: React.FC<QRCodeComponentProps> = ({ value }) => {
  return (
    <div className="d-flex justify-content-center my-4">
      <QRCode value={value} size={250} fgColor="#2d2d30" bgColor="#FFFFFF" />
    </div>
  );
};

export default QRCodeComponent;
