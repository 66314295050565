import clsx from "clsx";
import ReactInputMask from "react-input-mask";

import { ComponentProps } from "react";
import {
  Control,
  Controller,
  FieldErrors,
  RegisterOptions,
} from "react-hook-form";

interface Props extends Omit<ComponentProps<"input">, "ref"> {
  control: Control<any>;
  errors: FieldErrors;
  name: string;
  mask?: string;
  maskChar?: string;
  validation: RegisterOptions;
  label: string;
}

export const Input: React.FC<Props> = ({
  className = "",
  control,
  errors,
  name,
  mask = "",
  maskChar = "",
  validation,
  label,
  ...rest
}) => {
  return (
    <>
      <Controller
        control={control}
        name={name}
        rules={validation}
        render={({ field }) => (
          <div className="form-floating">
            <ReactInputMask
              id={field.name}
              name={field.name}
              mask={mask}
              maskChar={maskChar}
              placeholder={label}
              className={clsx(
                `${className} form-control placeholder-color`,
                errors[name] && "border-danger"
              )}
              // style={{
              //   border: "none",
              //   borderBottom: "1px solid #ccc",
              //   borderRadius: 0,
              //   boxShadow: "none",
              // }}
              onChange={field.onChange}
              value={field.value}
              {...rest}
            />
            <label htmlFor={name}>{label}</label>
          </div>
        )}
      />
      {errors[name] && (
        <span className="text-danger mt-2">
          {errors[name]?.message?.toString()}
        </span>
      )}
    </>
  );
};
