import React from "react";
import Modal from "react-modal";

Modal.setAppElement("#root");

interface SuccessModalProps {
  show: boolean;
  onHide: () => void;
}

const SuccessModal: React.FC<SuccessModalProps> = ({ show, onHide }) => {
  return (
    <Modal
      isOpen={show}
      onRequestClose={onHide}
      contentLabel="Modal de Sucesso"
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.3)",
          zIndex: 1000,
        },
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          padding: "40px",
          textAlign: "center",
          borderRadius: "8px",
          background: "#fff",
          boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
          width: "100%",
          maxWidth: "600px",
        },
      }}
    >
      <div
        onClick={onHide}
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          cursor: "pointer",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-x"
          viewBox="0 0 24 24"
        >
          <line x1="18" y1="6" x2="6" y2="18"></line>
          <line x1="6" y1="6" x2="18" y2="18"></line>
        </svg>
      </div>

      <div className="mb-4">
        <svg
          fill="#143166"
          height="135px"
          width="135px"
          version="1.1"
          id="Capa_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="-1.56 -1.56 55.12 55.12"
          xmlSpace="preserve"
          stroke="#143166"
        >
          <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
          <g
            id="SVGRepo_tracerCarrier"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></g>
          <g id="SVGRepo_iconCarrier">
            <g>
              <path d="M26,0C11.664,0,0,11.663,0,26s11.664,26,26,26s26-11.663,26-26S40.336,0,26,0z M26,50C12.767,50,2,39.233,2,26 S12.767,2,26,2s24,10.767,24,24S39.233,50,26,50z"></path>
              <path d="M38.252,15.336l-15.369,17.29l-9.259-7.407c-0.43-0.345-1.061-0.274-1.405,0.156c-0.345,0.432-0.275,1.061,0.156,1.406 l10,8C22.559,34.928,22.78,35,23,35c0.276,0,0.551-0.114,0.748-0.336l16-18c0.367-0.412,0.33-1.045-0.083-1.411 C39.251,14.885,38.62,14.922,38.252,15.336z"></path>
            </g>
          </g>
        </svg>
      </div>
      <div className="mt-5 d-flex justify-content-center">
        <h4 style={{ color: "#143166", fontWeight: 600, maxWidth: 400 }}>
          Pagamento recebido com sucesso!
        </h4>
      </div>
    </Modal>
  );
};

export default SuccessModal;
