import clsx from "clsx";
import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  Path,
  RegisterOptions,
} from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

interface PhoneNumberInputProps<T extends FieldValues> {
  name: Path<T>;
  control: Control<any>;
  errors: FieldErrors;
  rules: RegisterOptions;
}

export const PhoneNumberInput = <T extends FieldValues>({
  name,
  control,
  errors,
  rules,
}: PhoneNumberInputProps<T>) => {
  return (
    <>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field: { ref, ...field } }) => (
          <PhoneInput
            {...field}
            inputProps={{
              ref,
              name: field.name,
            }}
            country={"br"}
            enableAreaCodes
            enableSearch
            placeholder="Celular"
            inputClass={clsx(
              "w-100 placeholder-color",
              errors[name] && "border-danger"
            )}
            buttonClass={clsx(errors[name] && "border-danger")}
            inputStyle={{
              height: 58,
            }}
          />
        )}
      />
      {errors[name] && (
        <span className="text-danger">{errors[name]?.message?.toString()}</span>
      )}
    </>
  );
};
